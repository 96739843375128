import { useQuery } from 'react-query';
import { search, MINIMUM_QUERY_LENGTH, searchInvestor } from './search-utils';
import { ONE_DAY } from './constants';

type SearchResults = {
  id: number;
  name: string;
};

const useSearch = (query: string, searchInvestors: boolean) => {
  return useQuery(
    // Search isn't case sensitive. Results for Aker, aker and Aker are now cached on the same key.
    ['search', { query: query?.toLowerCase() }],
    async (): Promise<SearchResults[]> => {
      let investors = [];
      let companies = [];
      if (searchInvestors) {
        investors = await searchInvestor(query);
        if (investors.length > 10) {
          investors = investors.slice(0, 10);
        }
      } else {
        companies = await search(query);
      }
      const results = await Promise.all([companies, investors]);
      return results.flat().map((item) => {
        const isInvestor =
          item?.investorAccounts?.find((acc: any) => acc.name)?.name || false;
        return {
          id: item.id,
          name: !isInvestor
            ? item.name
            : `Investor: ${isInvestor} (${item.id})`,
          isInvestor,
        };
      });
    },
    {
      enabled: query?.length >= MINIMUM_QUERY_LENGTH,
      staleTime: ONE_DAY,
    },
  );
};

export { useSearch, MINIMUM_QUERY_LENGTH };
