import React from 'react';
import { logger } from '@oms/utils';
import * as Sentry from '@sentry/browser';
import { navigate, isRedirect } from '@reach/router';
import { Box } from '@oms/ui-box';
import { Page } from 'components/Page';

const log = logger('components:ExceptionCatcher');

type Props = {
  // children: React.ReactNode;
  // location: WindowLocation & { state?: { isReload: boolean } };
};

type State = {
  exception?: Error;
  componentStack?: string;
  eventId?: string;
};

export class ExceptionCatcher extends React.Component<Props, State> {
  state: State = {};

  /** Report error to sentry and enter application error state */
  componentDidCatch(exception: Error, errorInfo: { componentStack: string }) {
    if (isRedirect(exception)) {
      throw exception;
    } else {
      const isChunkError = /Loading.*chunk \d+ failed/.test(exception.message);
      /** Avoids entering reload loop by checking if we just reloaded */
      const isRepeatingReload = window.history.state?.isReload;

      if (isChunkError && !isRepeatingReload) {
        log('Missing chunk error encountered, reloading');
        Sentry.captureMessage(
          'Reloading due to missing chunk (probably new code deployed)',
        );
        this.setReloadFlag(true);
        window.location.reload(true);
        return;
      }

      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);
        const eventId = Sentry.captureException(exception);
        this.setState({
          exception,
          componentStack: errorInfo.componentStack,
          eventId,
        });
      });
    }
  }

  setReloadFlag = (isReload: boolean) => {
    navigate(window.location.pathname, {
      state: { ...(window.history.state || {}), isReload },
    });
  };

  unsetReloadFlag = () => this.setReloadFlag(false);

  render() {
    const { children } = this.props;
    const { exception, eventId } = this.state;

    if (exception) {
      return (
        <Page title="Error caught">
          <Box>
            <div>
              <p>
                Sorry! You found a problem we need to fix. We are looking into
                it,
                <br /> but you can help us improve Arena by sending us feedback:
              </p>
              <code>{exception.message}</code>
              <br />
              <small>Error id: {eventId}</small>
              <button
                onClick={() =>
                  Sentry.showReportDialog({ eventId: this.state.eventId })
                }
              >
                Report error
              </button>
            </div>
          </Box>
        </Page>
      );
    }

    return children;
  }
}
