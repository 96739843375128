import React, { Suspense } from 'react';
import ReactGA from 'react-ga';
import { Link as RouterLink, BrowserRouter } from 'react-router-dom';
import { ReactQueryConfigProvider } from 'react-query';
import Jaws from '@oms/jaws';
import { JawsProvider } from '@oms/jaws-react';
import { Helmet } from 'react-helmet';
import { useField } from 'react-final-form';
import { Global, css } from '@xstyled/emotion';
import { UIProvider } from '@oms/ui-provider';
import { create, GlobalStyles } from '@oms/ui-theming';
import { Loading } from '@oms/ui-loading';
import { Box } from '@oms/ui-box';
import { AppLogo } from '@oms/ui-app-shell';
import { AuthProvider } from 'client/auth';
import moment from 'moment';

//import { hot } from 'react-hot-loader';

moment.updateLocale('en', {
  week: {
    dow: 1,
  },
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD.MM.YYYY',
    LL: 'Do MMMM YYYY',
    LLL: 'Do MMMM YYYY LT',
    LLLL: 'dddd Do MMMM YYYY LT',
  },
});

const instance = new Jaws({
  url: window.location.origin + '/server/secure',
  useWebSockets: false,
});

function TempLink(props: any) {
  return (
    <RouterLink
      {...props}
      state={props?.state || props?.to?.state}
      to={typeof props?.to === 'object' ? props?.to?.pathname : props?.to}
    />
  );
}

export function AppContext({ children }: { children: React.ReactNode }) {
  ReactGA.initialize('UA-101364630-4', {
    debug: true,
    gaOptions: { siteSpeedSampleRate: 100 },
  });
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <ReactQueryConfigProvider
      config={{
        queries: {
          refetchOnWindowFocus: false,
        },
      }}
    >
      <JawsProvider value={{ instance }}>
        <BrowserRouter>
          <Helmet>
            <link
              href="https://fonts.googleapis.com/css2?family=Barlow&display=swap"
              rel="stylesheet"
            />
          </Helmet>
          <Global
            styles={css`
              body {
                margin: 0;
              }
            `}
          />
          <UIProvider
            components={{ Link: TempLink, useField }}
            theme={
              create({
                fonts: {
                  body: 'Barlow',
                  heading: 'Barlow',
                },
              }) as any
            }
            global={
              <GlobalStyles
                fontFamily={`'Barlow', -apple-system, BlinkMacSystemFont,
            'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
            'Helvetica Neue', sans-serif`}
              />
            }
          >
            <Suspense
              fallback={
                <Box
                  p={8}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  minHeight="100vh"
                >
                  <AppLogo />
                  <Loading>Please wait</Loading>
                </Box>
              }
            >
              <AuthProvider>{children}</AuthProvider>
            </Suspense>
          </UIProvider>
        </BrowserRouter>
      </JawsProvider>
    </ReactQueryConfigProvider>
  );
}

// export default hot(module)(AppContext);
