import { useQuery, queryCache } from 'react-query';
import matchSorter from 'match-sorter';
import { countShares, mergeInvestorData, findLeftInvestors } from 'utils';
import {
  getPositionRanking,
  getCompany,
  getDates,
} from './topShareholder-utils';
import { ONE_DAY } from './constants';

// DateFormat '2020-07-27'
const pexip = {
  companyId: 17857829,
  currentDate: '2020-07-27',
  previousDate: '2020-07-20',
  date: '2020-07-27',
};

export const prefetchTopShareholderDates = async (
  companyId: string | number,
) => {
  return queryCache.prefetchQuery(
    ['topShareholderDates', { companyId }],
    async () => getDates(companyId),
  );
};
export const useTopShareholderDates = (companyId: string | number) => {
  return useQuery(
    ['topShareholderDates', { companyId }],
    async () => getDates(companyId),
    {
      enabled: !!companyId,
      staleTime: ONE_DAY,
    },
  );
};

export const usePositionRanking = ({
  companyId,
  date,
  shares,
}: any = pexip) => {
  return useQuery(
    ['positionRanking', { companyId, date, shares }],
    async () => getPositionRanking({ companyId, date, shares }),
    {
      enabled: companyId && date,
      staleTime: ONE_DAY,
    },
  );
};

export const useCompany = ({ companyId, date }: any = pexip) => {
  return useQuery(
    ['shareData', { companyId, date }],
    async () => getCompany({ companyId, date } as any),
    {
      enabled: companyId && date,
      staleTime: ONE_DAY,
    },
  );
};

export const useTopShareholders = ({
  companyId,
  currentDate,
  previousDate,
  query,
}: any) => {
  const { data: company } = useCompany({ companyId, date: currentDate });
  const { data: previousCompany } = useCompany({
    companyId,
    date: previousDate,
  });
  const { data: currentRanking } = usePositionRanking({
    companyId,
    date: currentDate,
    shares: company?.shares,
  });
  const { data: previousRanking, ...rest } = usePositionRanking({
    companyId,
    date: previousDate,
    shares: previousCompany?.shares,
  });
  if (currentRanking && previousRanking) {
    const merged = mergeInvestorData(currentRanking, previousRanking);
    const summary = countShares({ data: currentRanking });
    const shareholders = matchSorter(merged, query, {
      keys: ['name'],
    });
    const removedShareholders = findLeftInvestors(
      currentRanking,
      previousRanking,
    );
    return {
      data: {
        summary,
        shareholders,
        removedShareholders,
        currentRanking,
        company,
      },
      ...rest,
    };
  }

  return {
    data: {
      summary: undefined,
      shareholders: [],
      removedShareholders: [],
      currentRanking: [],
      company,
    },
    ...rest,
  };
};
