export const countShares = (currentInvestorData) => {
  if (!currentInvestorData.data) return {};

  const result = currentInvestorData.data.reduce(
    (previousValue, item) => ({
      shownShares: previousValue.shownShares + item.numberOfStocks,
      foreignShares:
        item.country !== 'NOR'
          ? previousValue.foreignShares + item.numberOfStocks
          : previousValue.foreignShares,
      companyShares:
        item.type === 'COMP'
          ? previousValue.companyShares + item.numberOfStocks
          : previousValue.companyShares,
      privateShares:
        item.type === 'PRIV'
          ? previousValue.privateShares + item.numberOfStocks
          : previousValue.privateShares,
      nomineeShares:
        item.type === 'NOM'
          ? previousValue.nomineeShares + item.numberOfStocks
          : previousValue.nomineeShares,
      brokerShares:
        item.type === 'BRO' || item.type === 'MEG'
          ? previousValue.brokerShares + item.numberOfStocks
          : previousValue.brokerShares,
      konShares:
        item.type === 'KON'
          ? previousValue.konShares + item.numberOfStocks
          : previousValue.konShares,
    }),
    {
      shownShares: 0,
      foreignShares: 0,
      privateShares: 0,
      nomineeShares: 0,
      companyShares: 0,
      brokerShares: 0,
      konShares: 0,
    },
  );

  return result;
};

export const mergeInvestorData = (
  currentInvestorData,
  previousInvestorData,
) => {
  if (!currentInvestorData) return null;
  if (currentInvestorData && !previousInvestorData) return currentInvestorData;

  const result = [];
  currentInvestorData.forEach((currentWeek) => {
    const investorId = currentWeek.investorId;
    const investorAccountId = currentWeek.investorAccountId;

    const lastWeekStocks = previousInvestorData.find(
      (lastWeek) =>
        lastWeek.investorId === investorId &&
        lastWeek.investorAccountId === investorAccountId,
    );

    const data = {
      ...currentWeek,
    };

    if (lastWeekStocks) {
      const isChanged = lastWeekStocks.ranking !== currentWeek.ranking;
      const stockDifference =
        currentWeek.numberOfStocks - lastWeekStocks.numberOfStocks;
      data.rankChange = isChanged
        ? lastWeekStocks.ranking - currentWeek.ranking
        : 0;
      data.previousRanking = isChanged && lastWeekStocks.ranking;
      data.stockDifference = stockDifference !== 0 ? stockDifference : 0;
      data.previousNumberOfStocks = lastWeekStocks.numberOfStocks;
    } else {
      data.rankChange = Infinity;
      data.stockDifference = 0;
    }
    return result.push(data);
  });
  return result;
};

export const findLeftInvestors = (
  currentInvestorData,
  previousInvestorData,
) => {
  if (!currentInvestorData || !previousInvestorData) return null;

  const result = [];
  previousInvestorData.forEach((previousWeek) => {
    const investorId = previousWeek.investorId;
    const inBothDatasets = currentInvestorData.find(
      (currentWeek) => currentWeek.investorId === investorId,
    );

    if (!inBothDatasets) {
      return result.push(previousWeek);
    }
  });

  if (result.length < 1) return null;
  return result;
};
