import { get } from 'utils/fetch.js';
import { percentageOf } from 'utils';
import { TOP_SHAREHOLDERS_URL } from 'constants/urls';
import { Position } from './types';

const formatDate = (date: Date) => date.toISOString().split('T')[0]?.trim();

export const getPositionRanking = async ({
  companyId,
  date,
  shares,
}: any): Promise<Position[]> => {
  const endpoint = `${TOP_SHAREHOLDERS_URL}/company/${companyId}/position-ranking`;
  const result = await get(`${endpoint}?date=${formatDate(date)}`);
  if (!result.ok) throw result;
  const data = await result.json();
  if (shares) {
    return data.map((item: any) => ({
      ...item,
      percentageOfStocks:
        shares > 0 ? percentageOf(item.numberOfStocks, shares) : 0,
    }));
  }
  return data;
};

export const getCompany = async ({ companyId, date }: any) => {
  const endpoint = `${TOP_SHAREHOLDERS_URL}/company/${companyId}/`;
  const result = await get(`${endpoint}?date=${formatDate(date)}`);
  if (!result.ok) throw result;
  return await result.json();
};

export const getDates = async (companyId: string | number): Promise<Date[]> => {
  const result = await get(
    `${TOP_SHAREHOLDERS_URL}/company/${companyId}/dates`,
  );
  if (!result.ok) throw result;
  const data = await result.json();
  const dates = data.map((date: string) => new Date(date));
  return dates;
};

export const getInvestorDates = async (
  investorId: string | number,
): Promise<Date[]> => {
  const result = await get(
    `${TOP_SHAREHOLDERS_URL}/investor/${investorId}/dates`,
  );
  if (!result.ok) throw result;
  const data = await result.json();
  const dates = data.map((date: string) => new Date(date));
  return dates;
};
