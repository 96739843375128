import { SUBSCRIPTION_URL, PRODUCT_URL, ORDER_URL } from 'constants/urls';
import { get, put } from 'utils/fetch.js';

type SubType = {
  subscriptionId: number;
  userIds: number[];
};

export const getSubscription = async (id: number) => {
  const result = await get(`${SUBSCRIPTION_URL}/${id}`);
  if (!result.ok) throw result;
  return await result.json();
};

export const addUsers = async ({ subscriptionId, userIds }: SubType) => {
  if (!subscriptionId || !userIds?.length)
    throw new Error('Missing data editSubscription');
  const result = await put(
    `${SUBSCRIPTION_URL}/${subscriptionId}/addusers`,
    JSON.stringify({ userIds }),
  );
  if (!result.ok) throw result;
};

export const removeUsers = async ({ subscriptionId, userIds }: SubType) => {
  const result = await put(
    `${SUBSCRIPTION_URL}/${subscriptionId}/removeusers`,
    JSON.stringify({ userIds }),
  );
  if (!result.ok) throw result;
};

export const getProduct = async () => {
  const result = await get(PRODUCT_URL);
  if (!result.ok) throw result;
  return await result.json();
};

type OrderLine = {
  productPlanVariationId: number;
};

export const getOrder = async (orderId: number | undefined) => {
  if (!orderId) throw new Error('Missing order Id');
  const result = await get(`${ORDER_URL}/${orderId}`);
  if (!result.ok) throw result;
  return await result.json();
};

export const settleOrder = async (orderId: number, settled: boolean) => {
  const result = await put(
    `${ORDER_URL}/${orderId}`,
    JSON.stringify({ settled: !settled }),
  );
  return result.ok;
};
