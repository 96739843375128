import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';
import { Integrations as ApmIntegrations } from '@sentry/apm';
import { ReactQueryDevtools } from 'react-query-devtools';

import ensureIntlSetup from 'utils/ensureIntlSetup';
import { App as Routes } from './routes';
import { AppContext } from './app-context';
import { prefetchUser } from 'client/auth';

// We use this to kickoff an attempt to fetch the user first thing
prefetchUser();

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://18898ef1870e4089a4ec649c12b24308@sentry.io/2488071',
    integrations: [new ApmIntegrations.Tracing()],
    tracesSampleRate: 0.25,
  });
}

const App = () => (
  <React.Suspense fallback={null}>
    <ReactQueryDevtools initialIsOpen={false} />
    <AppContext>
      <Routes />
    </AppContext>
  </React.Suspense>
);

const elem = document.getElementById('root');
const render = () => {
  ReactDOM.render(<App />, elem);
};

ensureIntlSetup().then(() => {
  render();
  if (process.env.NODE_ENV !== 'production') {
    module.hot.accept(render);
  }
});

// Boots service worker. In case of emergency, change register to unregister
// below and re-deploy. This will kill the service workers. Do NOT simply remove
// this line or delete the public/service-worker.js file from the server,
// explicitly unregistring is required, anything else is a noop.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  // Override default behavior of waiting for all tabs to close before
  // refreshing the cached files. The below callback will force a reload when
  // a new service worker is detected.
  //
  // TODO Probably better to ask user first, example "A new version is
  // available, reload now?"
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event) => {
        if (event.target.state === 'activated') {
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  },
});
