import React from 'react';
import { css, Global } from '@xstyled/emotion';

type PrintGuardProps = {
  canPrint?: boolean;
};

export const PrintGuard = ({ canPrint }: PrintGuardProps) => {
  return (
    <Global
      styles={
        canPrint
          ? css`
              #leftAside,
              #rightAside,
              #top,
              footer {
                @media print {
                  display: none;
                }
              }
              .print-only {
                display: none;
                @media print {
                  display: initial;
                }
              }
              @media print {
                body::after {
                  content: '© ${new Date().getFullYear()} Euronext OMS. Allrights reserved.';
                }
              }
            `
          : css`
              @media print {
                #root {
                  display: none;
                }
                body::before {
                  content: 'Print is not included in your Arena subscription.';
                }
                body::after {
                  content: 'Contact us to upgrade.';
                }
              }
            `
      }
    />
  );
};
