import React, { Suspense, useEffect } from 'react';
import styled from '@xstyled/emotion';
import { NavLink } from 'react-router-dom';
import { useMedia } from '@oms/ui-media';
import { Heading } from '@oms/ui-heading';
import { Stack } from '@oms/ui-stack';
import { useDocumentTitle } from '@oms/ui-utils';
import { Box } from '@oms/ui-box';
import { Spinner } from '@oms/ui-spinner';

const PageHeader = styled.box`
  min-height: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 80rem;
  width: 100%;
  margin: 0 auto;
`;

const PageNav = styled.nav`
  display: flex;
  margin: 4 -4 0 -4;
`;

const PageLink = styled(NavLink)`
  padding: 2 4;
  border-top: md;
  border-top-color: transparent;
  text-decoration: none;
  color: white;
  &[aria-current='page'] {
    color: text;
    background-color: surface-2;
    border-top-color: selected;
  }
`;

type NavLinkItem = {
  label: string;
  path: string;
  pathname?: string;
};
type PageProps = {
  title: string;
  documentTitle?: string;
  navLinks?: NavLinkItem[];
  actions?: React.ReactElement;
  children?: React.ReactNode;
  breadcrumbs?: React.ReactElement;
};

const paddingProps = {
  breadcrumb: {
    pt: 2,
    px: 4,
    pb: 4,
  },
  navlink: {
    pt: 4,
    px: 4,
    pb: 0,
  },
  both: {
    pt: 2,
    px: 4,
    pb: 0,
  },
  none: {
    pt: 4,
    px: 4,
    pb: 4,
  },
};

function Page({
  title,
  documentTitle,
  navLinks = [],
  children,
  breadcrumbs,
}: PageProps) {
  const media = useMedia();

  useDocumentTitle({
    prefix: 'Arena',
    title: documentTitle || title,
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const padding =
    breadcrumbs && navLinks.length > 0
      ? paddingProps.both
      : breadcrumbs
      ? paddingProps.breadcrumb
      : navLinks
      ? paddingProps.navlink
      : paddingProps.none;

  return (
    <>
      <Box bg="surface-3">
        <PageHeader {...padding}>
          {breadcrumbs && (
            <Box my={1} color="inverse-text">
              {breadcrumbs}
            </Box>
          )}
          <Heading
            variant={media.isMobile ? 'heading5' : 'heading4'}
            color="inverse-text"
            mt={2}
          >
            {title}
          </Heading>
          {navLinks?.length > 0 && (
            <PageNav>
              {navLinks?.map(({ label, path, pathname = path }) => (
                <PageLink key={path} to={pathname}>
                  {label}
                </PageLink>
              ))}
            </PageNav>
          )}
        </PageHeader>
      </Box>
      <Stack
        gap={4}
        maxWidth="80rem"
        mx="auto"
        width="100%"
        py={4}
        px={4}
        minHeight="80vh"
      >
        <Suspense
          fallback={
            <Box center minHeight="50vh">
              <Spinner size="lg" />
            </Box>
          }
        >
          <>{children}</>
        </Suspense>
      </Stack>
    </>
  );
}
export { Page };
