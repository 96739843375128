import React from 'react';
import { AuthenticatedUser } from 'client/types';

export const LoggedInUser = ({
  user,
}: {
  user: Partial<AuthenticatedUser> | undefined;
}) => {
  if (!user?.isAuthenticated) return null;
  const { givenName, surname, customers } = user;
  const company = customers?.find((item) => item.name)?.name;
  return (
    <>
      {givenName} {surname} ({company})
    </>
  );
};
