import { get } from 'utils/fetch.js';
import { TOP_SHAREHOLDERS_URL } from 'constants/urls';

type SharesType = {
  companyId: string | number;
  date: string;
};

export const getPositionRanking = async ({ companyId, date }: SharesType) => {
  const endpoint = `${TOP_SHAREHOLDERS_URL}/company/${companyId}/position-ranking`;
  const result = await get(`${endpoint}?date=${date}`);
  if (!result.ok) throw result;
  return await result.json();
};

export const getShareData = async ({ companyId, date }: SharesType) => {
  const endpoint = `${TOP_SHAREHOLDERS_URL}/company/${companyId}/`;
  const result = await get(`${endpoint}?date=${date}`);
  if (!result.ok) throw result;
  return await result.json();
};

export const getDates = async (companyId: string | number) => {
  const result = await get(
    `${TOP_SHAREHOLDERS_URL}/company/${companyId}/dates`,
  );
  if (!result.ok) throw result;
  return (await result.json()) as string[];
};

export const getInvestorDates = async (investorId: string | number) => {
  const result = await get(
    `${TOP_SHAREHOLDERS_URL}/investor/${investorId}/dates`,
  );
  if (!result.ok) throw result;
  return (await result.json()) as string[];
};
