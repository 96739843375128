export const formatCurrency = (numberToFormat: number) => {
  if (isNaN(numberToFormat)) return '-';

  return Intl.NumberFormat('nb-NO', {
    style: 'currency',
    currency: 'NOK',
  }).format(Number(numberToFormat));
};

export default formatCurrency;
