let win;
if (typeof window === 'undefined') {
  win = { location: {} };
} else {
  win = window;
}

/* eslint max-len: [0] */
export const HOSTNAME = `//${process.env.JAWS_URL || win.location.hostname}:${
  window.location.port
}`;
export const BASE_URL = `${HOSTNAME}/server`;
export const BASE_SECURE_URL = `${BASE_URL}/secure`;
export const LOGGED_IN = `${BASE_SECURE_URL}/me`;
export const AUTHENTICATION_URL = `${BASE_URL}/authentication`;

export const AUTH_URL = `${AUTHENTICATION_URL}/user/login`;
export const LOGOUT_URL = `${AUTHENTICATION_URL}/user/logout`;
export const ADMIN_AUTH_URL = `${AUTHENTICATION_URL}/admin/login`;
export const ADMIN_LOGOUT_URL = `${AUTHENTICATION_URL}/admin/logout`;
export const USER_RESET_TOKEN_URL = `${AUTHENTICATION_URL}/user/forgot-password`;
export const USER_SET_PASSWORD_URL = `${AUTHENTICATION_URL}/user/set-password`;
export const ADMIN_RESET_TOKEN_URL = `${AUTHENTICATION_URL}/admin/forgot-password`;
export const ADMIN_SET_PASSWORD_URL = `${AUTHENTICATION_URL}/admin/set-password`;

export const USER_URL = `${BASE_SECURE_URL}/user`;
export const ADMIN_USER_URL = `${BASE_SECURE_URL}/admin`;
export const CUSTOMER_URL = `${BASE_SECURE_URL}/customer`;
export const PRODUCT_URL = `${BASE_URL}/product`;
export const TOP_SHAREHOLDERS_URL = `${BASE_SECURE_URL}/products/topshareholders`;
export const COMPANY_SEARCH_URL = `${TOP_SHAREHOLDERS_URL}/company-search`;
export const INVESTOR_SEARCH_URL = `${TOP_SHAREHOLDERS_URL}/investor-search`;
export const INVESTOR_URL = `${BASE_SECURE_URL}/products/topshareholders`;

export const SUBSCRIPTION_URL = `${BASE_SECURE_URL}/subscription`;
export const ORDER_URL = `${BASE_SECURE_URL}/order`;
export const SIGNUP_URL = `${BASE_URL}/signup`;
export const GICS_URL = `${TOP_SHAREHOLDERS_URL}/company/collect/gics`;
export const ALL_URL = `${TOP_SHAREHOLDERS_URL}/company/all`;

export const favoriteInvestorURL = (userId: string | number | undefined) =>
  `${USER_URL}/${userId}/favorite/investor`;
