import React from 'react';
import { AppNavigationDrawerSection } from '@oms/ui-app-shell';

export const Adminlinks = ({
  showLinks,
}: {
  showLinks: boolean | undefined;
}) => {
  if (!showLinks) return null;
  return (
    <AppNavigationDrawerSection
      title="Dev links"
      routes={[
        { path: '/admin', label: 'Admin login' },
        { path: '/reset', label: 'Forgot password' },
        { path: '/admin/reset', label: 'Admin Forgot password' },
        {
          path: '/authentication/user/set-password/token123',
          label: 'Reset password',
        },
        {
          path: '/authentication/admin/set-password/token123',
          label: 'Reset admin password',
        },
      ]}
    />
  );
};
