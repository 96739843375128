import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@oms/ui-icon-button';
import { Popover } from '@oms/ui-popover';
import { Search as UISearch } from '@oms/ui-search';
import { light } from '@oms/ui-icon';
import { useMedia } from '@oms/ui-media';
import { Box } from '@oms/ui-box';
import { VisuallyHidden } from '@oms/ui-visually-hidden';
import { useId } from '@oms/ui-utils';
import { useSearch, MINIMUM_QUERY_LENGTH } from 'client/search';
import { prefetchTopShareholderDates } from 'client/topShareholder';

export function Search() {
  const media = useMedia();
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState('');
  const { data = [], status } = useSearch(inputValue, false);
  const id = useId('search');

  const handleNavigate = (changes: any) => {
    if (changes && changes.id) {
      // This should speed things up a little
      const { id, isin, name, ticker } = changes;
      prefetchTopShareholderDates(changes.id);
      navigate(
        `/${changes.isInvestor ? 'investor' : 'instrument'}/${changes.id}`,
        { state: { id, isin, name, ticker } },
      );
      setInputValue('');
    }
  };

  if (media.isMobile) {
    return (
      <Popover
        disclosure={
          <IconButton icon={light.faSearch} style={{ marginLeft: 'auto' }} />
        }
      >
        <VisuallyHidden id={id + '-label'} htmlFor={id}>
          Search
        </VisuallyHidden>
        <UISearch
          placeholder="Search for company, ticker or ISIN"
          minWidth={{ base: '95vw' }}
          onInputValueChange={(value: any) => setInputValue(value)}
          onChange={handleNavigate}
          debounce={500}
          itemToString={(item: any) => item?.name}
          status={status}
          items={data}
          minimumQueryLength={MINIMUM_QUERY_LENGTH}
          isClearable={true}
        />
      </Popover>
    );
  }
  return (
    <Box
      as="form"
      display="flex"
      alignItems="center"
      mx="auto"
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
      }}
    >
      <VisuallyHidden id={id + '-label'} htmlFor={id}>
        Search
      </VisuallyHidden>
      <UISearch
        id={id}
        labelId={id + '-label'}
        placeholder="Search company, ticker or ISIN"
        minWidth={{ md: '20rem', lg: '30rem' }}
        onInputValueChange={(value: any) => setInputValue(value)}
        onChange={handleNavigate}
        debounce={500}
        itemToString={(item: any) => item?.name}
        status={status}
        items={data}
        minimumQueryLength={MINIMUM_QUERY_LENGTH}
        isClearable={true}
      />
    </Box>
  );
}
