import { post, get, put, del } from 'utils/fetch.js';
import { USER_URL, CUSTOMER_URL } from 'constants/urls';
import { UserType, CustomerType } from 'client/types';

export const createUser = async ({
  givenName,
  surname,
  email,
  roles,
  customer,
}: UserType) => {
  const result = await post(
    USER_URL,
    JSON.stringify({ givenName, surname, email, roles, customer }),
  );
  if (!result.ok) throw result;
};

export const getUsers = async () => {
  const result = await get(USER_URL);
  if (!result.ok) throw result;
  const users = await result.json();
  const sortedUsers = users.sort((a: UserType, b: UserType) =>
    a.givenName.localeCompare(b.givenName),
  );
  return sortedUsers;
};

type UpdateUserTypes = {
  userId: string;
  givenName: string;
  surname: string;
};

export const updateUsersName = async ({
  userId,
  givenName,
  surname,
}: UpdateUserTypes) => {
  const result = await put(
    `${USER_URL}/${userId}`,
    JSON.stringify({
      givenName,
      surname,
    }),
  );
  if (!result.ok) throw result;
};

export const createCustomer = async ({ hubspotId, name }: CustomerType) => {
  const result = await post(
    CUSTOMER_URL,
    JSON.stringify({
      hubspotId,
      name,
    }),
  );
  if (!result.ok) throw result;
  return await result.json();
};

export const updateCustomer = async ({
  customerId,
  name,
  hubspotId,
}: CustomerType) => {
  const result = await put(
    `${CUSTOMER_URL}/${customerId}`,
    JSON.stringify({ name, hubspotId }),
  );
  if (!result.ok) throw result;
};

export const deleteCustomer = async (customerId: number) => {
  const { ok, status, statusText } = await del(`${USER_URL}/${customerId}`);
  return {
    ok,
    status: status + ' ' + statusText,
  };
};

export const getUser = async (id: number) => {
  const result = await get(`${USER_URL}/${id}`);
  if (!result.ok) throw result;
  return await result.json();
};

export const getCustomers = async () => {
  const result = await get(CUSTOMER_URL);
  if (!result.ok) throw result;
  const customers: CustomerType[] = await result.json();
  const sortedCustomers = customers.sort((a, b) =>
    a.name.localeCompare(b.name),
  );
  return sortedCustomers;
};

export const getCustomer = async (id: number | string) => {
  const result = await get(`${CUSTOMER_URL}/${id}`);
  if (!result.ok) throw result;
  return await result.json();
};
