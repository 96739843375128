import React from 'react';
import {
  AppSkipLink,
  AppSkipLinksNavigation as SkipLinksNavigation,
  Id,
} from '@oms/ui-app-shell';

function AppSkipLinksNavigation() {
  return (
    <SkipLinksNavigation>
      <AppSkipLink href={`#${Id.row}`}>Skip to content</AppSkipLink>
    </SkipLinksNavigation>
  );
}

export { AppSkipLinksNavigation };
