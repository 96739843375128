import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation, queryCache, MutationConfig } from 'react-query';
import { useSubmitMutation } from './submit';
import {
  getAuth,
  authenticateUser,
  authenticateAdmin,
  requestPasswordReset,
  requestAdminPasswordReset,
  logout,
  logoutAdmin,
  setNewPassword,
  setNewAdminPassword,
} from './auth-utils';
import { AuthenticatedUser } from './types';
import { ONE_DAY } from './constants';

const AuthContext = React.createContext<Partial<AuthenticatedUser> | undefined>(
  {},
);
export const useAuth = () => React.useContext(AuthContext);

// We use this to kickoff an attempt to fetch the user first thing
export const prefetchUser = () => queryCache.prefetchQuery('auth', getAuth);

export function useGetAuth() {
  return useQuery('auth', getAuth, {
    suspense: true,
    initialData: {
      isAdmin: false,
      isUser: false,
      isAuthenticated: false,
    },
    staleTime: ONE_DAY,
    initialStale: true,
    refetchOnWindowFocus: true,
  });
}

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { data: auth } = useGetAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export function useAuthenticate(options: MutationConfig<any> = {}) {
  return useSubmitMutation(authenticateUser, {
    ...options,
    onSuccess: (data, variables) => {
      if (typeof options?.onSuccess === 'function') {
        options?.onSuccess(data, variables);
      }
      queryCache.invalidateQueries('auth');
    },
  });
}

export function useAuthenticateAdmin(options: MutationConfig<any> = {}) {
  return useSubmitMutation(authenticateAdmin, {
    ...options,
    onSuccess: (data, variables) => {
      if (typeof options?.onSuccess === 'function') {
        options?.onSuccess(data, variables);
      }
      queryCache.invalidateQueries('auth');
    },
  });
}

export function useRequestAdminPasswordReset(options?: MutationConfig<any>) {
  return useSubmitMutation(requestAdminPasswordReset, options);
}

export function useRequestPasswordReset(options?: MutationConfig<any>) {
  return useSubmitMutation(requestPasswordReset, options);
}

export function useConfirmToken(options?: MutationConfig<any>) {
  return useSubmitMutation(setNewPassword, options);
}

export function useConfirmAdminToken(options?: MutationConfig<any>) {
  return useSubmitMutation(setNewAdminPassword, options);
}

export function useLogout() {
  const user = useAuth();
  const navigate = useNavigate();
  const fetcher = user?.isAdmin ? logoutAdmin : logout;
  return useMutation(fetcher, {
    onSuccess: () => {
      queryCache.invalidateQueries('auth').then(() => {
        user?.isAdmin ? navigate('/admin') : navigate('/login');
      });
    },
  })[0];
}
