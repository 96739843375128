import { useMutation, MutationFunction, MutationConfig } from 'react-query';
import { FORM_ERROR } from 'final-form';

function useSubmitMutation(
  mutationFn: MutationFunction<any, any>,
  options?: MutationConfig<any>,
) {
  const [mutate, state] = useMutation(mutationFn, options);

  const submit = (values: any, _form: any, onErrorCallback: any) => {
    const promise = mutate(values, {
      /**
       * Mutate takes a second options argument.
       * Options will be merged with the objects object in useMutation
       * */
      onError: async (error: any) => {
        /** Pass the error to final form */
        onErrorCallback({
          [FORM_ERROR]: error?.statusText || 'Error',
        });
      },
    });
    return promise;
  };
  return [submit, state] as const;
}

export { useSubmitMutation };
