export const nonAuthenticatedRoutes = [
  { path: '/login', label: 'Login' },
  { path: '/signup/1', label: 'Signup' },
  { path: '/contact', label: 'Contact' },
  {
    path: '/admin',
    label: 'Backoffice',
  },
];

export const authenticatedRoutes = [
  { path: '/', label: 'Front page' },
  { path: '/dashboard/watchlist', label: 'Watchlist' },
  { path: '/dashboard/markets', label: 'Markets' },
  { path: '/contact', label: 'Contact' },
];

export const adminRoutes = [
  { path: '/admin/admins', label: 'Administrators' },
  { path: '/admin/customers', label: 'Customers' },
];
