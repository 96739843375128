import React from 'react';
import {
  AppNavigationBar as NavigationBar,
  AppNavigationDrawer,
  AppNavigationDrawerSection,
} from '@oms/ui-app-shell';
import { Link } from '@oms/ui-link';
import { Stack } from '@oms/ui-stack';
import { Box } from '@oms/ui-box';
import { Button } from '@oms/ui-button';
import { useAuth, useLogout } from 'client/auth';
import { Search } from 'components/Search';
import { ArenaLogoLink } from 'components/ArenaLogo';
import { Adminlinks } from './Adminlinks';
import { LoggedInUser } from './LoggedInUser';
import {
  adminRoutes,
  authenticatedRoutes,
  nonAuthenticatedRoutes,
} from './utils';

function AppNavigationBar() {
  const user = useAuth();
  const logout = useLogout();

  const logoElement = <ArenaLogoLink />;

  const navigationElement = (
    <AppNavigationDrawer
      actions={({ drawer }: any) =>
        user?.isAuthenticated ? (
          <Button
            onClick={() => {
              logout().then(() => drawer.hide());
            }}
          >
            Sign out
          </Button>
        ) : undefined
      }
    >
      <Stack gap={8}>
        {!user?.isAuthenticated && (
          <AppNavigationDrawerSection
            title="Menu"
            routes={nonAuthenticatedRoutes}
          />
        )}
        {user?.isAuthenticated && (
          <AppNavigationDrawerSection
            title="Dashboard"
            routes={authenticatedRoutes}
          />
        )}
        {user?.isAdmin && (
          <AppNavigationDrawerSection
            title="OMS Administrator"
            routes={adminRoutes}
          />
        )}
        <Adminlinks
          showLinks={process.env.NODE_ENV === 'development' && user?.isAdmin}
        />
      </Stack>
    </AppNavigationDrawer>
  );

  return (
    <NavigationBar logo={logoElement} navigation={navigationElement}>
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        ml={{ base: 'auto', md: 'initial' }}
        justifyContent={{ base: 'initial', md: 'space-between' }}
      >
        {user?.isAuthenticated && <Search />}
        <LoggedInUser user={user} />
        {!user?.isAuthenticated && (
          <Link
            variant="secondary"
            borderRadius="rounded"
            to="/login"
            ml="auto"
          >
            Login
          </Link>
        )}
      </Box>
    </NavigationBar>
  );
}

export { AppNavigationBar };
