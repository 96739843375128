import {
  LOGGED_IN,
  AUTH_URL,
  ADMIN_AUTH_URL,
  ADMIN_RESET_TOKEN_URL,
  USER_RESET_TOKEN_URL,
  LOGOUT_URL,
  ADMIN_LOGOUT_URL,
  ADMIN_SET_PASSWORD_URL,
  USER_SET_PASSWORD_URL,
} from 'constants/urls';
import { get, post } from 'utils/fetch.js';
import {
  Me,
  Principals,
  Product,
  AuthenticatedUser,
  AuthenticateUserOptions,
} from './types';

export const getAuth = async (): Promise<Partial<AuthenticatedUser>> => {
  const result = await get(LOGGED_IN);
  if (!result.ok) throw result;
  const data: Me = await result.json();
  try {
    const {
      principalInfo,
      products,
      customers,
      givenName,
      surname,
      email,
    } = data;
    const principalsPredicate = (item: Principals) => item.sub;
    const topShareholderPredicate = (product: Product) =>
      product.productName.toLowerCase() === 'topshareholders';

    const principals = principalInfo.principals.find(principalsPredicate);
    const topShareHolders = products.find(topShareholderPredicate);
    const { productPlan, historyMonths } = topShareHolders || {};

    if (principals) {
      return {
        userId: principals.sub,
        givenName,
        surname,
        email,
        userType: principals.userType?.toLowerCase(),
        isAuthenticated: principalInfo.authenticated,
        isUser: principals.userType?.toLowerCase() === 'user',
        canPrint: productPlan !== 'Standard',
        isAdmin: principals.userType?.toLowerCase() === 'admin',
        products,
        productPlan,
        historyMonths,
        customers,
      };
    }
    return {
      isAuthenticated: false,
      isUser: false,
      isAdmin: false,
    };
  } catch (e) {
    throw new Error(e);
  }
};

export const authenticateUser = async ({
  username,
  password,
}: AuthenticateUserOptions) => {
  const result = await post(
    AUTH_URL,
    JSON.stringify({ userName: username, password }),
  );
  if (!result.ok) throw result;
};

export const authenticateAdmin = async ({
  username,
  password,
}: AuthenticateUserOptions) => {
  const result = await post(
    ADMIN_AUTH_URL,
    JSON.stringify({ userName: username, password }),
  );
  if (!result.ok) throw result;
  return result;
};

export const requestPasswordReset = async ({ username }: any) => {
  await post(`${USER_RESET_TOKEN_URL}`, username);
};

export const requestAdminPasswordReset = async ({ username }: any) => {
  await post(`${ADMIN_RESET_TOKEN_URL}`, username);
};

export const logout = async () => await post(LOGOUT_URL);

export const logoutAdmin = async () => await post(ADMIN_LOGOUT_URL);

type PasswordTypes = {
  token: string;
  password: string;
  [key: string]: any;
};

export const setNewAdminPassword = async ({
  token,
  password,
}: PasswordTypes) => {
  const result = await post(
    ADMIN_SET_PASSWORD_URL,
    JSON.stringify({ token, password }),
  );

  if (!result.ok) throw result;
};

export const setNewPassword = async ({ token, password }: PasswordTypes) => {
  const result = await post(
    USER_SET_PASSWORD_URL,
    JSON.stringify({ token, password }),
  );

  if (!result.ok) throw result;
};
