import { PRODUCT_ONE, PRODUCT_TWO, PRODUCT_THREE } from 'constants/products';
import { formatCurrency } from 'utils';

type Pricing = {
  maxUsers: number;
  price: number;
};

type TopShareHolders = {
  product: string;
  features: string[];
  pricing: Pricing[];
};

export const topShareHolders: TopShareHolders[] = [
  {
    product: PRODUCT_ONE,
    features: ['List of 20 Largest Shareholders ', '1 month historical data '],
    pricing: [
      { maxUsers: 2, price: 12500 },
      { maxUsers: 5, price: 7500 },
      { maxUsers: 15, price: 7000 },
      { maxUsers: Infinity, price: 105000 },
    ],
  },
  {
    product: PRODUCT_TWO,
    features: [
      'List of 30 largest shareholders ',
      'One year historical data ',
      'Shareholder demographics',
      'Excel Export',
    ],
    pricing: [
      { maxUsers: 2, price: 16000 },
      { maxUsers: 5, price: 12000 },
      { maxUsers: 15, price: 11000 },
      { maxUsers: Infinity, price: 135000 },
    ],
  },
  {
    product: PRODUCT_THREE,
    features: [
      'List of 50 largest shareholders ',
      'Two years historical data ',
      'Shareholder demographics',
      'Excel Export',
      'Cross connection of investors',
      'Share information',
    ],
    pricing: [
      { maxUsers: 2, price: 19000 },
      { maxUsers: 5, price: 15000 },
      { maxUsers: 15, price: 14000 },
      { maxUsers: Infinity, price: 175000 },
    ],
  },
];

export const getPrice = (product: string, users: number) => {
  const pricePerUserPerYear = topShareHolders
    ?.find((m) => m.product === product)
    ?.pricing.find((price) => price.maxUsers >= users)?.price;
  const pricePerUserPerMonth =
    users > 15
      ? formatCurrency(Number(pricePerUserPerYear))
      : formatCurrency(Number(pricePerUserPerYear) / 12);
  return {
    pricePerUserPerYear: formatCurrency(Number(pricePerUserPerYear)),
    pricePerUserPerMonth,
    totalYearly: formatCurrency(Number(pricePerUserPerYear) * Number(users)),
  };
};
