import React from 'react';
import { AppStatus as Status } from '@oms/ui-app-shell';

/**
 * A banner placed on top of the application used to communicate some kind of status message.
 */
const AppStatus = () => {
  return (
    <Status title="A mesage from Oslo Market Solutions" status="success">
      Oslo Market Solutions is now part of Infront. As such we have begun
      rebranding our market solutions. You can expect the same great products,
      but with a fresh new look.
    </Status>
  );
};

export { AppStatus };
