export const formatShares = (number) => {
  const isNumberString = /[0-9,.]+/;
  const isNumeric = (input) => {
    if (typeof input === 'number') return true;
    if (typeof input !== 'string') return false;
    return isNumberString.test(input);
  };

  if (!number || !isNumeric(number)) return '-';

  return new Intl.NumberFormat().format(number);
};

export default formatShares;
