import React, { Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AppShell } from '@oms/ui-app-shell';
import { Spinner } from '@oms/ui-spinner';
import { Box } from '@oms/ui-box';

/** Utils */
import { useAuth } from 'client/auth';
/** Components */
import { PrintGuard } from 'components/PrintGuard';
import { AppStatus } from 'components/AppStatus';
import { AppNavigationBar } from 'components/AppNavigationBar';
import { AppFooter } from 'components/AppFooter';
import { AppSkipLinksNavigation } from 'components/AppSkipLinksNavigation';
import { ExceptionCatcher } from 'components/ExceptionCatcher';

/** Pages */
const Admin = React.lazy(() => import('pages/Admin'));
const Admins = React.lazy(() => import('pages/Admins'));
const Contact = React.lazy(() => import('pages/Contact'));
const Customer = React.lazy(() => import('pages/Customer'));
const CustomerSubscription = React.lazy(
  () => import('pages/CustomerSubscription'),
);
const CustomerSubscriptions = React.lazy(
  () => import('pages/CustomerSubscriptions'),
);
const CustomerUsers = React.lazy(() => import('pages/CustomerUsers'));
const Customers = React.lazy(() => import('pages/Customers'));
const Dashboard = React.lazy(() => import('pages/Dashboard'));
const ForgotPassword = React.lazy(() => import('pages/ForgotPassword'));
const Instrument = React.lazy(() => import('pages/Instrument'));
const Investor = React.lazy(() => import('pages/Investor'));
const LandingPage = React.lazy(() => import('pages/LandingPage'));
const Login = React.lazy(() => import('pages/Login'));
const Logout = React.lazy(() => import('pages/Logout'));
const NewPassword = React.lazy(() => import('pages/NewPassword'));
const NotFound = React.lazy(() => import('pages/NotFound'));
const MissingAttributes = React.lazy(() => import('pages/MissingAttributes'));
const Profile = React.lazy(() => import('pages/Profile'));
const Report = React.lazy(() => import('pages/Report'));

//Disabled signup page ARENA-851
// const SignUp = React.lazy(() => import('pages/SignUp'));
const Success = React.lazy(() => import('pages/Success'));

export function App() {
  const user = useAuth();

  return (
    <>
      <PrintGuard canPrint={user?.canPrint} />
      <ExceptionCatcher>
        <AppShell
          skipLinks={<AppSkipLinksNavigation />}
          status={<AppStatus />}
          navigationBar={<AppNavigationBar />}
          footer={<AppFooter />}
        >
          <Suspense
            fallback={
              <Box center height="100%">
                <Spinner size="lg" />
              </Box>
            }
          >
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/contact" element={<Contact />} />
              {/* Disabled signup page ARENA-851 */}
              {/* <Route path="/signup/*" element={<SignUp />} /> */}
              <Route
                path="/login"
                element={
                  user?.isUser ? <Navigate to="/dashboard" /> : <Login />
                }
              />
              <Route
                path="/admin"
                element={
                  user?.isAdmin ? (
                    <Navigate to="/customers" />
                  ) : (
                    <Login target="admin" />
                  )
                }
              />
              <Route path="/reset" element={<ForgotPassword />} />
              <Route
                path="/admin/reset"
                element={<ForgotPassword target="admin" />}
              />
              <Route
                path="/authentication/user/set-password/:token"
                element={<NewPassword />}
              />
              <Route
                path="/authentication/admin/set-password/:token"
                element={<NewPassword target="admin" />}
              />
              {user?.isAuthenticated && (
                <>
                  <Route path="/dashboard/*" element={<Dashboard />} />
                  <Route
                    path="/instrument/:companyId/*"
                    element={<Instrument />}
                  />
                  <Route path="/investor/:investorId" element={<Investor />} />

                  <Route path="/report" element={<Report />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route
                    path="/missing-attributes"
                    element={<MissingAttributes />}
                  />
                  <Route path="/logout" element={<Logout />} />
                </>
              )}
              {user?.isAdmin && (
                <>
                  <Route
                    path="/admin/customers/:customerId"
                    element={<Customer />}
                  >
                    <Navigate to="subscriptions" replace />
                    <Route
                      path="subscriptions"
                      element={<CustomerSubscriptions />}
                    />
                    <Route
                      path="subscriptions/:subscriptionId"
                      element={<CustomerSubscription />}
                    />
                    <Route path="users" element={<CustomerUsers />} />
                    <Route />
                  </Route>
                  <Route path="/admin" element={<Admin />}>
                    <Navigate to="customers" />
                    <Route path="admins" element={<Admins />} />
                    <Route path="customers" element={<Customers />} />
                  </Route>
                </>
              )}
              <Route path="/success" element={<Success />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </AppShell>
      </ExceptionCatcher>
    </>
  );
}
