import template from 'url-template';
import URLEncodedFormData from 'utils/URLEncodedFormData';

const buildResource = (resource) => {
  if (typeof resource === 'string') {
    return resource;
  } else if (resource.url) {
    const { url, ...variables } = resource;
    const parsedUrl = template.parse(url);

    return parsedUrl.expand(variables);
  }

  throw new Error(
    'Resource must be either a string or an object with a url template property and variables. ' +
      `Got ${resource}.`,
  );
};

const getContentType = (body) => {
  let contentType = 'application/json; charset=UTF-8';
  if (body instanceof FormData) {
    contentType = 'multipart/form-data; charset=UTF-8';
  } else if (body instanceof URLEncodedFormData) {
    contentType = 'application/x-www-form-urlencoded; charset=UTF-8';
  }

  return contentType;
};

export const get = async (resource, headers) => {
  const result = await fetch(buildResource(resource), {
    credentials: 'same-origin',
    headers: headers ? new Headers(headers) : {},
  });

  if (result.status === 401) {
    const cause = result.headers.get('x-oms-cause');
    window.location.assign(`/login?reason=${cause || '401'}`);
  }

  return result;
};

export const post = (resource, body, headers) => {
  let parsedBody;
  if (!body) {
    parsedBody = undefined;
  } else if (body && getContentType(body).includes('multipart/form-data')) {
    parsedBody = body;
  } else {
    parsedBody = body.toString();
  }

  return fetch(buildResource(resource), {
    method: 'POST',
    credentials: 'same-origin',
    body: parsedBody,
    headers: new Headers({
      'Content-Type': getContentType(body),
      ...headers,
    }),
  });
};

export const filePost = (resource, body, headers) => {
  let parsedBody;
  if (!body) {
    parsedBody = undefined;
  } else if (body && getContentType(body).includes('multipart/form-data')) {
    parsedBody = body;
  } else {
    parsedBody = body.toString();
  }

  return fetch(buildResource(resource), {
    method: 'POST',
    credentials: 'same-origin',
    body: parsedBody,
    headers: new Headers({
      ...headers,
    }),
  });
};

export const filePut = (resource, body, headers) => {
  let parsedBody;
  if (!body) {
    parsedBody = undefined;
  } else if (body && getContentType(body).includes('multipart/form-data')) {
    parsedBody = body;
  } else {
    parsedBody = body.toString();
  }

  return fetch(buildResource(resource), {
    method: 'PUT',
    credentials: 'same-origin',
    body: parsedBody,
    headers: new Headers({
      ...headers,
    }),
  });
};

export const put = (resource, body, headers) => {
  return fetch(buildResource(resource), {
    method: 'PUT',
    credentials: 'same-origin',
    body: body ? body.toString() : undefined,
    headers: new Headers({
      'Content-Type': getContentType(body),
      ...headers,
    }),
  });
};

export const del = (resource, headers) => {
  return fetch(buildResource(resource), {
    method: 'DELETE',
    credentials: 'same-origin',
    headers: headers ? new Headers(headers) : {},
  });
};
