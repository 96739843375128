import { COMPANY_SEARCH_URL, INVESTOR_SEARCH_URL } from 'constants/urls';
import { get } from 'utils/fetch';

export const COMPANY_LIMIT = 20;
export const MINIMUM_QUERY_LENGTH = 3;

export const search = async (value: string) => {
  const result = await get(
    `${COMPANY_SEARCH_URL}?text=${value}&limit=${COMPANY_LIMIT}`,
  );
  if (!result.ok) throw result;
  const data = await result.json();
  return data;
};

export const searchInvestor = async (value: string) => {
  const result = await get(`${INVESTOR_SEARCH_URL}?text=${value}`);
  if (!result.ok) throw result;
  const data = await result.json();
  return data;
};
